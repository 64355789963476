<template>
  <div class="pages">
    <h1>CRA-RS no interior</h1>
    <p v-if="loading">Carregando dados...</p>
    <div class="wrapper" v-if="!loading">
      <div>
        <label>Seccionais</label>
        <div class="editor-wrapper">
          <editor
                  ref="editor_content_sec"
                  autofocus
                  holder-id="codex-editor-content-sec"
                  save-button-id="save-button-sec"
                  :init-data="initDataSec"
                  @save="saveSec"
                  @ready="onReadySec"
                  @change="onChangeSec"
          />
        </div>
      </div>
      <div>
        <label>Delegados</label>
        <div class="editor-wrapper">
          <editor
                  ref="editor_content_del"
                  autofocus
                  holder-id="codex-editor-content-del"
                  save-button-id="save-button-del"
                  :init-data="initDataDel"
                  @save="saveDel"
                  @ready="onReadyDel"
                  @change="onChangeDel"
          />
        </div>
      </div>
      <div>
        <label>Postos Avançados</label>
        <div class="editor-wrapper">
          <editor
                  ref="editor_content_adv"
                  autofocus
                  holder-id="codex-editor-content-adv"
                  save-button-id="save-button-adv"
                  :init-data="initDataAdv"
                  @save="saveAdv"
                  @ready="onReadyAdv"
                  @change="onChangeAdv"
          />
        </div>
      </div>
      <div>
        <label>Representantes</label>
        <div class="editor-wrapper">
          <editor
                  ref="editor_content_rep"
                  autofocus
                  holder-id="codex-editor-content-rep"
                  save-button-id="save-button-rep"
                  :init-data="initDataRep"
                  @save="saveRep"
                  @ready="onReadyRep"
                  @change="onChangeRep"
          />
        </div>
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="saving">{{ saving ? 'Salvando...' : 'Salvar' }}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'edititem',
  data() {
    return {
      loading: true,
      saving: false,
      item: {
        sec: '',
        del: '',
        adv: '',
        rep: '',
      },
      initDataSec: {
        blocks: []
      },
      initDataDel: {
        blocks: []
      },
      initDataAdv: {
        blocks: []
      },
      initDataRep: {
        blocks: []
      },
    }
  },
  mounted() {
    let self = this;
    axios.get(this.$apiUrl + 'cra-int/')
            .then(response => {
              self.item = response.data.item;
              self.item.sec = JSON.parse(self.item.sec);
              self.item.del = JSON.parse(self.item.del);
              self.item.adv = JSON.parse(self.item.adv);
              self.item.rep = JSON.parse(self.item.rep);
              self.initDataSec = self.item.sec;
              self.initDataDel = self.item.del;
              self.initDataAdv = self.item.adv;
              self.initDataRep = self.item.rep;
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.saving = true;
        let self = this;

        let itemToSave = Object.assign({}, this.item);
        itemToSave.sec = JSON.stringify(itemToSave.sec);
        itemToSave.del = JSON.stringify(itemToSave.del);
        itemToSave.adv = JSON.stringify(itemToSave.adv);
        itemToSave.rep = JSON.stringify(itemToSave.rep);

        axios.put(this.$apiUrl + 'cra-int', itemToSave)
                .then(response => {
                  self.saving = false;
                  this.$alert(response.data.message, 'Sucesso', 'success').then(() => {});
                })
                .catch(error => {
                  this.$alert(error.response.data.message, 'Erro', 'error');
                  console.log(error.response.data.error);
                  self.saving = false;
                });
      }
    },
    saveSec(response) {
      this.item.sec = response;
    },
    onReadySec() {
      this.$refs['editor_content_sec'].save();
    },
    onChangeSec() {
      this.$refs['editor_content_sec'].save();
    },
    saveDel(response) {
      this.item.del = response;
    },
    onReadyDel() {
      this.$refs['editor_content_del'].save();
    },
    onChangeDel() {
      this.$refs['editor_content_del'].save();
    },
    saveAdv(response) {
      this.item.adv = response;
    },
    onReadyAdv() {
      this.$refs['editor_content_adv'].save();
    },
    onChangeAdv() {
      this.$refs['editor_content_adv'].save();
    },
    saveRep(response) {
      this.item.rep = response;
    },
    onReadyRep() {
      this.$refs['editor_content_rep'].save();
    },
    onChangeRep() {
      this.$refs['editor_content_rep'].save();
    }
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }
  }

  .delete-btn {
    background-color: #b13336;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;

    &:hover {
      background-color: darken(#b13336, 20%);
    }
  }
</style>
